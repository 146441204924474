export const DRAWING = 'drawing'
export const TOGGLE_THEME = 'TOGGLE_DARK'
export const CABLE_SELECTED = 'cable-selected'
export const TITLE_BY_SELECTION = 'title-by-selection'
export const CLEAR_SELECTION = 'clear-selection'
export const TOGGLE_VISIBILITY = 'toggle-visibility'
export const CLICK = 'click'
export const ENTER = 'enter'
export const FOCUS_ON = 'focus-on'
export const SEARCH_SELECTION = 'search-selection'
export const FOCUS_ON_CITY = 'focus-on-city'
export const DESTROY_MAP = 'destroy-map'
export const PARAMS_SELECTION = 'params-selection'
