export const TOGGLE_DARK = 'CHANGE_THEME'
export const SHARE_LINK = 'SHARE_LINK'
export const IS_MOBILE = 'IS_MOBILE'
export const IS_DRAWING = 'IS_DRAWING'
export const LOCATE_USER = 'LOCATE_USER'
export const CURRENT_SELECTION = 'CURRENT_SELECTION'
export const GET_CABLE_SELECTED = 'GET_CABLE_SELECTED'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const TOGGLE_SIDEBAR_MODE = 'TOGGLE_SIDEBAR_MODE'
export const TOGGLE_BUY_DIALOG = 'TOGGLE_BUY_DIALOG'
export const TOGGLE_ALERT_DIALOG = 'TOGGLE_ALERT_DIALOG'
export const TOGGLE_MESSAGE_DIALOG = 'TOGGLE_MESSAGE_DIALOG'
export const TOGGLE_ISSUES_DIALOG = 'TOGGLE_ISSUES_DIALOG'
export const TOGGLE_VERIFICATION_DIALOG = 'TOGGLE_VERIFICATION_DIALOG'
export const BUY_TYPE = 'BUY_TYPE'
export const BUY_REQUEST = 'BUY_REQUEST'
export const ISSUE_REQUEST = 'ISSUE_REQUEST'

// --------------- DATA -------------------

export const GET_PREMIUM_DATA = 'GET_PREMIUM_DATA'
export const GET_SUBMARINE = 'GET_SUBMARINE'
export const GET_DATA_CENTERS = 'GET_DATA_CENTERS'
export const GET_NETWORKS = 'GET_NETWORKS'
export const GET_IX_FACILITIES = 'GET_IX_FACILITIES'
export const GET_PREMIUM_SELECTED_BOUNDS = 'GET_PREMIUM_SELECTED_BOUNDS'
export const GET_PREMIUM_SELECTED_FEATURES = 'GET_PREMIUM_SELECTED_FEATURES'
export const GET_SELECTION_DATA = 'GET_SELECTION_DATA'
export const GET_CABLES_BY_ORG = 'GET_CABLES_BY_ORG'
export const GET_SUBSEA_CABLE = 'GET_SUBSEA_CABLE'
export const QUERY_RESULTS = 'QUERY_RESULTS'

export const STATISTICS_DATA = 'STATISTICS_DATA'
