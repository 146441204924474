export const BEGIN_CREATION = 'BEGIN_CREATION'
export const BEGIN_EDITION = 'BEGIN_EDITION'
export const EDIT_FEATURE_PROPS = 'EDIT_FEATURE_PROPS'
export const CONFIRM_CREATION = 'CONFIRM_CREATION'
export const DELETE_FEATURE = 'DELETE_FEATURE'
export const CANCEL = 'CANCEL'
export const RESET_SCENE = 'RESET_SCENE'
export const SELECTION = 'SELECTION'
export const RESET_LIST = 'RESET_LIST'
export const SET_LIST = 'SET_LIST'
export const EDIT_FEATURE = 'EDIT_FEATURE'
export const TOGGLE_MAP_FORM_LOADING = 'TOGGLE_MAP_FORM_LOADING'
