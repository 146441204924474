export const providers = [
  {
    img: 'https://cdn1.infrapedia.com/logos/mandrill-logo.svg',
    value: 'mandrill'
  }
  // {
  //   img:
  //     'https://cdn.auth0.com/manhattan/versions/1.1118.0/assets/logos/mailgun/mailgun-logo.svg',
  //   value: 'mailgun'
  // },
  // {
  //   img:
  //     'https://cdn.auth0.com/manhattan/versions/1.1118.0/assets/email-providers/smtp.svg',
  //   value: 'smtp'
  // },
  // {
  //   img:
  //     'https://cdn.auth0.com/manhattan/versions/1.1118.0/assets/logos/sendgrid/sendgrid-logo.svg',
  //   value: 'sendgrid'
  // }
]
