var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"vph-full",class:{
    'no-overflow': _vm.isMobileProfileDrawer
  },attrs:{"id":"profile-layout__main-wraper"}},[_c('i-navbar',{attrs:{"role":"navigation","is-user-navbar":true},on:{"toggle-aside-width":_vm.handleToggleSidebarWidth}}),_c('el-aside',{staticClass:"mt12 no-overflow hidden-md-and-down cubic-transition",class:{
      'adjust-width': !_vm.isSidebarFullWidth || _vm.$route.path.includes('create')
    },attrs:{"width":"200px"}},[_c('ul',{staticClass:"pt7 h-fit-full",attrs:{"role":"group"}},[_vm._l((_vm.links),function(link,i){return [_c('li',{key:i,staticClass:"h10",attrs:{"role":"listitem","title":link.label}},[_c('router-link',{staticClass:"inline-flex align-items-center pl5 color-inherit h-fit-full w-fit-full no-outline",class:_vm.checkURL(link),staticStyle:{"width":"90%"},attrs:{"exact":"","to":link.url,"id":`${link.label
                .trim()
                .toLowerCase()
                .replace(' ', '-')}-link`}},[_c('fa',{staticClass:"cubic-transition icon",attrs:{"icon":link.icon}}),_c('span',{staticClass:"label",class:{
                'transition-all-delay-520ms': !_vm.$route.path.includes('create')
              }},[_vm._v(" "+_vm._s(link.label)+" ")])],1)],1)]})],2)]),_c('i-message-dialog'),_c('i-mobile-profile-drawer',{staticClass:"hidden-md-and-up",attrs:{"visibility":_vm.isMobileProfileDrawer},on:{"update:visibility":function($event){_vm.isMobileProfileDrawer=$event},"close":_vm.handleToggleMobileProfileDrawer}}),_c('h-mobile-menu',{staticClass:"hidden-md-and-up"}),_c('transition',{attrs:{"mode":"out-in","name":"animated super-fast","enter-active-class":"fadeIn","leave-active-class":"fadeOut"}},[_c('router-view')],1),_c('i-footer',{staticClass:"profile-footer hidden-md-and-down transition-all",class:{
      'is-create-route': _vm.$route.path.includes('create?'),
      'is-create-facility-route': _vm.$route.path.includes('create-facility')
    }}),_c('v-tour',{attrs:{"name":"profile-tour","steps":_vm.profileTourSteps,"callbacks":_vm.tourCallbacks,"options":{ highlight: true }},scopedSlots:_vm._u([{key:"default",fn:function(tour){return [_c('transition',{attrs:{"name":"animated faster3x","enter-active-class":"fadeIn","leave-active-class":"fadeOut"}},[_vm._l((tour.steps),function(step,index){return [(tour.currentStep === index)?_c('v-step',{key:index,attrs:{"step":step,"previous-step":tour.previousStep,"next-step":tour.nextStep,"stop":tour.stop,"is-first":tour.isFirst,"is-last":tour.isLast,"labels":tour.labels,"highlight":true}},[(tour.currentStep === _vm.profileTourSteps.length - 1)?[_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('button',{staticClass:"v-step__button",on:{"click":tour.previousStep}},[_vm._v(" Previous step ")]),_c('button',{staticClass:"v-step__button",on:{"click":_vm.restartVTour}},[_vm._v(" Restart tour ")]),_c('button',{staticClass:"v-step__button",on:{"click":tour.stop}},[_vm._v(" Finish ")])])]:_vm._e()],2):_vm._e()]})],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }